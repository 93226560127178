<template>

  <div class="title">
    <div class="container">
      <h1>Online primary health care</h1>
    </div>
  </div>

  <section id="section_1">

    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-7">
          <p>Development of a united single network which combines multiple medical software systems for remote health monitoring:</p>
          <ul>
            <li>self identifies a person ( face ID)</li>
            <li>measuring the basic physical indicators of a person</li>
            <li>a detailed questionnaire on person’s well-being</li>
            <li>online transfer of information to the medical staff on duty</li>
            <li>saving an electronic medical record on the analytical platform.</li>
          </ul>
          <p>Unified data allows us to analyse patterns of the health status and changes.</p>
          <ul>
            <li>Remote Medical Care</li>
            <li>Electronic Health Record</li>
            <li>IT system for hospitals</li>
            <li>Telemedicine Cloud</li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-5">
          <img src="../assets/solution-healts-care-6.jpg" class="mb-3" alt="">
        </div>
      </div>
      <p>Health telemonitoring technologies focus on prevention, regular health checks and achieving the patient’s long-term goals.</p>
    </div>
  </section>
  <section id="section_2">
    <div class="container col-lg-8">
      <img src="../assets/solution-healts-care-7.jpg" alt="">
    </div>
  </section>
  <section id="section_3">
    <div class="container">
      <div class="row align-items-center align-items-xl-stretch">
        <div class="col-lg-6 col-xl-7 border-left mb-4 mb-lg-0">

          <div class="text">

            <p>“Why couldn’t, many of them (patients) were asking, it be possible to contact health services in the same way as banking services? Why do some clinicians still hold so vehemently to the old ways of doing things when we now have (and they now have) new and very different digital tools to use”.</p>

            <p>
            The History of Telemedicine in the United Kingdom<br>
            Malcolm J. Fisk1<br>
            ISBN 978-619-90601-5-5<br>
            A Century of Telemedicine:<br>
            Curatio Sine Distantia et Tempora<br>
            A World Wide Overview<br>
            Part IV            </p>

          </div>

        </div>
        <div class="col-lg-6 col-xl-5">
          <img src="../assets/solution-healts-care-8.jpg" alt="">
        </div>
      </div>
    </div>
  </section>

  <section id="section_4">
    <div class="container">
      <img src="../assets/solution-healts-care-9.jpg" alt="" class="m-auto d-block">
    </div>
  </section>

  <section id="section_5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-7 col-lg-9">

          <h4>DIMECO SYSTEM IMPLEMENTATION RESULTS:</h4>

          <p>company employee at any time, with the appearance of even a slight malaise, promptly receives remote primary medical care, without leaving the territory of the business center.</p>

          <p><b>Result:</b> the number of sick leave absence will decrease, as well as the expenditure of working time for the employee's trip to the polyclinic for an initial medical examination will decrease. The spread of viral diseases will be reduced.</p>


        </div>
        <div class="col-md-5 col-lg-3">
          <img src="../assets/dimeco-care-pro.png" alt="">
        </div>
      </div>
    </div>
  </section>

  <section></section>

</template>


<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'HealthCarePage',
  setup() {
    useMeta({
      title: 'Health care',
    })
  }
}
</script>

<style scoped>
  .title {
    min-height: 35vh;
    padding: 50px 0;
    background: url('../assets/slider4.jpg') no-repeat center;
    background-size: cover;
    color: #fff;
  }
  .title .container {
    max-width: 1000px;
  }
  section {
    padding: 50px 0;
  }
  section:nth-child( 2n +1 ) {
    background-color: rgb(245, 245, 245);
  }
  #section_3 .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 3px solid #e5b10b;
    padding-left: 1em;
    min-height: 100%;
  }

</style>
