<template>
  <section id="header">

    <div class="hat d-none d-sm-block w-100 border-bottom">
      <div class="container d-flex gap-3 justify-content-end">
        <span>DIMECCO OY</span>
        <span>Linnankatu 11 A 6 FI-00160 Helsinki</span>
        <a href="mailto:info@dimeco.fi">info@dimeco.fi</a>
      </div>
    </div>

    <div class="container">

      <ul id="nav" class="nav">

      </ul>

      <nav class="navbar navbar-expand-md">

        <router-link to="/" class="navbar-brand"><img src="../assets/logo.svg" alt=""></router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link" exact>Home</router-link>
            </li>
            <li class="nav-item">

            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                Solutions
              </a>
              <ul class="dropdown-menu  animate slideIn" aria-labelledby="navbarDropdown">
                <li><router-link to="/solutions/solution-safe" class="nav-link">Ensuring comprehensive labor safety</router-link></li>
                <li><router-link to="/solutions/solution-primary-health-care" class="nav-link">Online primary health care</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                Products
              </a>
              <ul class="dropdown-menu  animate slideIn" aria-labelledby="navbarDropdown">
                <li><router-link to="/products/dimeco-safe" class="nav-link">DIMECO SAFE</router-link></li>
                <li><router-link to="/products/dimeco-care" class="nav-link">DIMECO CARE</router-link></li>
                <li><router-link to="/products/dimeco-care-pro" class="nav-link">DIMECO CARE PRO</router-link></li>
              </ul>
            </li>
          </ul>
            <a class="btn btn-outline-dark login" type="submit" href="https://cp.medbank.net">Log In</a>
        </div>

      </nav>

    </div>
  </section>
  <div id="header-height"></div>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    msg: String
  },



  mounted() {

    window.addEventListener("load", function () {

      //colored menu dropdown elements
      Array.from(document.getElementsByClassName('router-link-active')).forEach(function (activeElement) {
        activeElement.parentElement.parentElement.parentElement.classList.add('active');
      });

      let menuCollapseHide = document.querySelectorAll('a.nav-link:not(.dropdown-toggle)');
      for (let item of menuCollapseHide) {
        item.addEventListener('click', function () {           console.log(menuCollapseHide);

          let menuCollapse = document.getElementById('navbarSupportedContent');
          menuCollapse.classList.remove('show');

          Array.from( document.getElementsByClassName('dropdown-menu') ).forEach(function (element) {
            element.classList.remove('show');
          });

          //colored menu dropdown elements
          Array.from( document.getElementsByClassName('dropdown')).forEach(function (element) {
            element.classList.remove('active');
          });

          this.parentElement.parentElement.parentElement.classList.add('active');
        });
      }

    });

    // make it as accordion for smaller screens
    if (window.innerWidth > 767) {

      document.querySelectorAll('.navbar .nav-item').forEach(function (everyitem) {

        everyitem.addEventListener('mouseover', function () {

          let el_link = this.querySelector('a[data-bs-toggle]');

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.add('show');
            nextEl.classList.add('show');
          }

        });
        everyitem.addEventListener('mouseleave', function () {
          let el_link = this.querySelector('a[data-bs-toggle]');

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.remove('show');
            nextEl.classList.remove('show');
          }


        })
      });

    }
    // end if innerWidth


  },

}


</script>

<style scoped>
  #header {
    position: fixed;
    background-color: #fff;
    width: 100%;
    z-index: 5;
  }
  .dropdown-menu {
    font-size: .9em;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    padding: .4em 1em;
    border-color: #eee;
    box-shadow: inset 0 9px 10px -6px rgba(101, 100, 100, 0.15);
    border-bottom: 4px solid #54b8cf;
  }
  .hat {
    color: #aaa;
    font-size: .8em;
  }
  .hat a {
    color: inherit;
    font-weight: 600;
  }
  .navbar-brand {
    max-width: 200px;
  }
  #header-height {
    min-height: 57px;
  }

  .navbar-nav li a {
    font-weight: 500;
  }
  .navbar-nav >li >a {
    font-size: .9em;
    text-transform: uppercase;
    font-weight: 700;
  }
  .dropdown.active>a,
  .router-link-active:focus,
  .router-link-active {
    color: #54b8cf;
  }

  .login {
    border-radius: 0;
    margin-bottom: 1em;
  }

@media (min-width: 200px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

  @media screen and (min-width: 768px) {
    .login {
      margin-bottom: 0;
    }
    .dropdown-menu {
      min-width: max-content;
    }
  }

  @media (min-width: 992px) {
    #header-height {
      min-height:78px;
    }

    .navbar-brand {
      max-width: 250px;
    }

  }

  .navbar-toggler {
    border: 0;
    outline: 0;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }

</style>