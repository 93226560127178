<template>
    <div class="container">
        <h1 class="text-center">Error 404</h1>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>