<template>

  <div class="title">
    <div class="container">
      <h1>Dimeco Care</h1>
    </div>
  </div>

  <section id="section_1">
    <div class="container">
      <p>The ergonomic and compact design of the device is perfect for any workplace but is also protected from theft.</p>
    </div>
  </section>

  <section id="section_2">

    <div class="container">
        <div class="col-12 col-lg-10 m-auto">
          <div class="d-xl-flex flex-row gap-3 justify-content-between align-items-center w-100 ">
            <div class="c">
              <ul>
                <li>Alcohol breathalyzer tester </li>
                <li>An infrared thermometer </li>
                <li>Display </li>
                <li>Microcomputer </li>
              </ul>
            </div>
            <div class="c">
              <img src="../assets/dimeco-care.png" alt="">
            </div>
            <div class="c">
              <ul>
                <li>Video camera</li>
                <li>Card reader</li>
                <li>Pulse oximetry</li>
                <li>Electrocardiography</li>
                <li>Blood pressure and pulse</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

  </section>

  <section id="section_3">
    <div class="container text-center ">
      <h4>THE IDENTIFICATION PROCESS, ALL MEASUREMENTS TAKE ONLY ABOUT 1,5 MINUTES!</h4>
    </div>
  </section>

  <section id="section_4">
    <div class="container col-lg-7 m-auto">
      <h4>DIMECO uses an innovative system to remove excess air, which makes testing in public as safe as possible</h4>
      <img src="../assets/care-05.png" alt="">

      <p>DIMECO uses an innovative system to remove excess air, which makes testing in public as safe as possible</p>
      <p>This ensures viruses and microbes don’t accumulate on the breathalyzer funnel, which would blow directly onto the next user.</p>
      <img src="../assets/care-06.jpg" alt="">

    </div>
  </section>

  <section id="section_5">
    <div class="container col-lg-7 m-auto">
      <p><b>PREHOSPITAL STROKE/INFARCTION DETECTION</b></p>
      <img src="../assets/care-07.png" alt="">
      <p>The ability to identify pre-stroke/infarction symptoms by analysing the dynamics of blood pressure and pulse measurements</p>

    </div>
  </section>

  <section></section>

</template>

<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'DimecoCarePage',
  setup() {
    useMeta({
      title: 'Dimeco Care',
    })
  }
}
</script>

<style scoped>

  img {
    max-width: 100%;
  }
  .title {
    min-height: 35vh;
    padding: 50px 0;
    background: url('../assets/back-care.png') no-repeat center;
    background-size: cover;
    color: #fff;
  }
  .title .container {
    max-width: 1000px;
  }

  section {
    padding: 50px 0;
  }
  section:nth-child( 2n ) {
    background-color: rgb(245, 245, 245);
  }
  #section_2 {
    font-size: 1.3em;
  }
  #section_2 img {
    width: 400px;
  }

</style>