<template>
  <section id="footer">
    <div class="container">
      <div class="d-lg-flex justify-content-between gap-4">

        <div class="col-lg-5">
          <div class="logo">
            <router-link to="/" class="navbar-brand"><img src="../assets/logo.svg" alt=""></router-link>
          </div>
          <div class="empty p-3"></div>
          <p>DIMECCO OY</p>
          <p>Business ID 3118730-6</p>
          <p>Linnankatu 11 A 6 FI-00160 Helsinki</p>
          <p>Email: <b><a href="mailto:info@dimeco.fi">info@dimeco.fi</a></b></p>
          <div class="empty p-3"></div>
        </div>

        <div class="">
          <h3>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
              <path d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z" fill="#fff"/>
            </svg>
            Solutions
          </h3>
          <ul>
            <li><router-link to="/solutions/solution-safe">Ensuring comprehensive labor safety</router-link></li>
            <li><router-link to="/solutions/solution-primary-health-care">Online primary health care</router-link></li>
          </ul>
          <div class="empty p-1"></div>
        </div>

        <div class="">
          <h3>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" fill="#fff"/></svg>
            Products</h3>
          <ul>
            <li><router-link to="/products/dimeco-safe">DIMECO SAFE</router-link></li>
            <li><router-link to="/products/dimeco-care">DIMECO CARE</router-link></li>
            <li><router-link to="/products/dimeco-care-pro">DIMECO CARE PRO</router-link></li>
          </ul>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'MainFooter',
  props: {
    msg: String
  }
}
</script>

<style scoped>
  #footer {
    padding: 2em 0;
    text-align: left;
    color: #fff;
    background-color: #1f1e1e;
    min-height: 10em;
    box-shadow: 0 -6px 0 0 #54b8cf;
  }
  .logo {
    max-width: 200px;
  }
  p {
    margin: 0;
    color: #bbb;
  }
  h3 {
    font-size: 1.2em;
    margin-bottom: .7em;;
  }
  ul {
    list-style: none;
    padding: 0
  }
  a {
    color: inherit;
    text-decoration: none;
    transition: color .3s;
    color: #bbb;
  }
  a:active, a:hover, a.router-link-active {
    text-decoration: none;
    color: #54b8cf;
  }
  li {
    margin-bottom: .3em;
  }
  svg {
    vertical-align: top;
  }
  @media (min-width: 992px) {
    .logo {
      max-width: 250px;
    }
  }
</style>