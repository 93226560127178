<template>
  <div class="title">
    <div class="container">
      <h1>Ensuring comprehensive labor safety focusing on the health of employees</h1>
    </div>
  </div>

  <section id="section_1">

    <div class="container">
      <div class="col-12 col-lg-10 m-auto">

        <p>Our solution is one of the way to perform the  EU strategic framework on health and safety at work 2021-2027 3 main objectives:</p>
        <ul>
          <li>anticipating and managing change in the new world of work brought about by the green, digital and demographic transitions;</li>
          <li>improving prevention of workplace accidents and illnesses;</li>
          <li>increasing preparedness for any potential future health crises.</li>
        </ul>
        <p><span style="font-size: 0.9em; color: #666">--<br>
        List of sources used:<br>
        Occupational safety and health in a changing world of work<br>
        EU strategic framework on health and safety at work 2021—2027</span>
        </p>

      </div>
    </div>
  </section>
  <section id="section_2">
    <div class="container">

      <h4>DIMECO CARE & DIMECO SAFE</h4>

      <p>Our unique complex consisting of the device and a software is widely available to the masses, easy to operate and install. The main characteristics are:</p>
      <ul>
        <li>it is connected to the single computer center (cloud data storage);</li>
        <li>it has intuitive interface</li>
        <li>it has extra fast testing speed (from 8 seconds to 1 minute).</li>
        <li>it is a universal complex that combines several diagnostic devices,</li>
        <li>it is mobile, and innovative methods of taking physiological indicators have been applied when creating this complex.</li>
      </ul>
      <img src="../assets/solution-safe-01.png" alt="">

    </div>
  </section>
  <section id="section_3">
    <div class="container">

      <div class="col-12 col-lg-8 m-auto col-xl-12 mb-0 mb-lg-4">
        <h4>DIMECO SAFE</h4>
        <h4>KEY BENEFITS</h4>
        <div class="row">
          <div class="col-xl-7 d-flex flex-column justify-content-around align-items-center gap-3 mb-4">

            <div class="card p-2">
                <div class="d-flex flex-row align-items-center">
                  <div class="number">1</div>
                  <div class="descr">Prohibiting access to the premises for employees who are over the alcohol limit or with a high temperature (more than 37°C)</div>
                </div>
            </div>

            <div class="card p-2">
                <div class="d-flex flex-row align-items-center">
                  <div class="number">2</div>
                  <div class="descr">Promptly informing responsible employees about visitors with signs of viral and infectious diseases, including COVID-19.</div>
                </div>
            </div>

            <div class="card p-2">
                <div class="d-flex flex-row align-items-center">
                  <div class="number">3</div>
                  <div class="descr">Identification of forgery, e.g. an employee's attempt to pass the checkpoint under a false name or using someone else’s ID. This is a result of the facial recognition system.</div>
                </div>
            </div>

            <div class="card p-2">
                <div class="d-flex flex-row align-items-center">
                  <div class="number">4</div>
                  <div class="descr">Increased control of staff member’s shift start and end times</div>
                </div>
            </div>

            <div class="card p-2">
                <div class="d-flex flex-row align-items-center">
                  <div class="number">5</div>
                  <div class="descr">Quick alerts regarding violations of workplace policies.</div>
                </div>
            </div>

          </div>
          <div class="col-xl-5">
            <img src="../assets/dimeco-safe.png" alt="">
          </div>
        </div>
      </div>

      <h5>ADDITIONAL ASSETS</h5>
      <div class="row assets">

        <div class="col-12 col-lg-6">
          <div class="card">
            The ability to create a "virtual checkpoint" using the alcohol and thermal control system. This will also function as an employee identification station and register their arrival/departure.
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            The possibility of combining Dimeco SAFE into a single network up to a regional level, with data analysis available across territories.
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            Availability of a system of key indicators for registering employees and visitors.
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            Unwanted persons and criminals can be identified using the facial recognition system.
          </div>
        </div>

      </div>

    </div>
  </section>
  <section id="section_4">
    <div class="container">

      <h4>DINECO CARE</h4>
      <div class="row">
        <div class="col-lg-8">
          <p>Development of software diagnostic complexes for express control of basic health indicators, united in a single network. we provide employees with daily monitoring: pulse pressure temperature saturation alcohol ECG (on demand, depending on the configuration - from 8 sec to 2 min)</p>
        </div>
        <div class="col-lg-4">
          <img src="../assets/dimeco-care.png" alt="">
        </div>
      </div>

      <h4>DIMECO SYSTEM FOR HAZARDOUS INDUSTRIES</h4>
      <div class="row">
        <div class="col-lg-8">
          <p>Remote pre-trip medical examinations of drivers pre-shift medical examinations of employees of the hazardous production health monitoring of employees at remote shifts, teams, transport preventive examination at work</p>

          <p><b>Results:</b> Reduced injuries at work and transport accidents due to poor health of drivers Production discipline improving Prevention of the shift (trip) of employees in a state of alcohol intoxication or in a poor health Opportunity for employees to monitor their health</p>
        </div>
        <div class="col-lg-4">
          <img src="../assets/dimeco-system.jpg" alt="">
        </div>
      </div>

    </div>
  </section>

  <section></section>

</template>


<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'SafetyPage',
  setup() {
    useMeta({
      title: 'Labor safety'
    })
  }
}
</script>

<style scoped>
.title {
  min-height: 35vh;
  padding: 50px 0;
  background: url('../assets/solution-safe-back.png') no-repeat center;
  background-size: cover;
  color: #fff;
}

.title .container {
  max-width: 1000px;
}

section {
  padding: 50px 0;
}

section:nth-child(2n) {
  background-color: rgb(245, 245, 245);
}

#section_2 {
  font-size: 1.3em;
}

#section_3 .card {
    border-radius: 0;
    width: 100%;
}
#section_3 .card .number {
  display: flex;
  justify-content: center;
  margin-right: .2em;
  font-weight: 300;
  min-width: 40px;
  font-size: 3em;
  color: #888;
  text-align: center;
  vertical-align: middle;
}
img {
  max-width: 100%;
  display: block;
  margin: auto;
}
#section_3 img {
  margin-top: 2em;
  width: 500px;
}
.assets .card {
  padding: 1em 1em 1em 5em;
  margin-bottom: 1em;
  background: url('../assets/check.svg') 1em center no-repeat;
  background-size: 3em;
}

</style>

